// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DefaultNavbarLink from "layouts/Navbars/DefaultNavbar/DefaultNavbarLink";

import Icon from "@mui/material/Icon";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import LogoutIcon from "@mui/icons-material/Logout";
import ApiIcon from "@mui/icons-material/Api";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Link } from "react-router-dom";

function DefaultNavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect();

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <MDBox px={0.5}>
        <DefaultNavbarLink icon="home" name="home" route="/" />
        <DefaultNavbarLink icon={<MonetizationOnIcon />} name="pricing" route="/pricing" />
        <DefaultNavbarLink icon={<ApiIcon />} name="documentation" route="/documentation" />
        <DefaultNavbarLink icon={<ContactPageIcon />} name="contact us" route="/contact-us" />
        {/* <DefaultNavbarLink icon={<VpnKeyIcon />} name="login" route="/login" /> */}
      </MDBox>
      <MDBox display={{ xs: "none", lg: "inline-block" }}>
        <MDButton component={Link} to="/pricing" variant="contained" color="primary" size="small">
          Get Free API Key
        </MDButton>
      </MDBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
