const plansData = [
  {
    id: "vk6y4x",
    stripe_id: "",
    name: "Basic",
    price: "0.00 USD /",
    period: "monthly",
    features: [
      "1,000 requests / month",
      {
        subFeatures: ["Hard Daily Limit"],
      },
      "5 requests / sec",
      "10 records/page for City API",
      "10 records/page for Country API",
    ],
  },
  {
    id: "cxgdv0",
    stripe_id: "price_1O0mbBB4JF4tf4WXiFr3bwMg",
    name: "Pro",
    price: "4.99 USD /",
    period: "monthly",
    features: [
      "10,000 requests / month",
      {
        subFeatures: ["$0.005 USD each other"],
      },
      "10 requests / sec",
      "30 records/page for City API",
      "No page size limit for Country API",
    ],
  },
  {
    id: "f6chao",
    stripe_id: "price_1O0mbbB4JF4tf4WXrC9vl9NO",
    name: "Ultra",
    price: "19.99 USD/",
    period: "monthly",
    features: [
      "50,000 requests / month ",
      {
        subFeatures: ["$0.003 USD each other"],
      },
      "15 requests / sec",
      "60 records/page for City API",
      "No page size limit for Country API",
    ],
  },
  {
    id: "bhab1m",
    stripe_id: "price_1O0mbwB4JF4tf4WX6bQXdLph",
    name: "Mega",
    price: "49.99 USD/",
    period: "monthly",
    features: [
      "200,000 requests / month",
      {
        subFeatures: ["$0.0001 USD each other"],
      },
      "30 requests / sec",
      "100 records/page for City API",
      "No page size limit for Country API",
    ],
  },
];

export default plansData;
