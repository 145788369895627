import CoverLayout from "layouts/CoverLayout";
import CodeSnippet from "ui-components/CodeSnippet";

function Tutorial() {
  const title = "CODE SNIPPET";
  const description =
    "The provided sample integration code demonstrates how to utilize API keys for interacting with the Geography API. It showcases making an authenticated GET request to retrieve data from the API.";
  return (
    <CoverLayout title={title} description={description}>
      <CodeSnippet />
    </CoverLayout>
  );
}

export default Tutorial;
