// components/Login.js
import { useEffect } from "react";

import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { useNavigate, useLocation } from "react-router";
import CoverLayout from "layouts/CoverLayout";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Auth } from "aws-amplify";
import { useMaterialUIController, setUser } from "context";
import { checkout } from "ui-components/Checkout";

function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  const [controller, dispatch] = useMaterialUIController();
  const { isNewUser, selectedPlanName } = controller;
  useEffect(() => {
    if (route === "authenticated") {
      const getUser = () => {
        try {
          Auth.currentUserInfo().then((currentUser) => {
            setUser(dispatch, currentUser);
          });
        } catch (error) {
          console.error("Error getting User Info after login:", error);
          throw error;
        }
      };
      const user = getUser(); // Call the getUser function
      console.log(user);
      const userEmail = user?.attributes?.email;
      const userName = user?.username;
      if (isNewUser && selectedPlanName !== "Basic") {
        checkout({ plan: selectedPlanName, navigate, userEmail, userName });
      } else {
        navigate("/dashboard");
      }
      //isNewUser ? checkout(selectedPlanName, navigate) : navigate("/dashboard");
    }
  }, [route, navigate, from, isNewUser, selectedPlanName, dispatch]);
  return (
    <CoverLayout shrinkCardWidth={true}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Access to free APIs
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <View className="auth-wrapper">
            <Authenticator />
          </View>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Login;
