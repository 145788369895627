// PlanDetailModel.js

class PlanDetailModel {
  constructor(data) {
    this.startDate = new Date(data.startDate);
    this.endDate = new Date(data.endDate);
    this.planId = data.planId;
  }
}

export default PlanDetailModel;
