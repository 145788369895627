import React, { useState, useEffect } from "react";
import { AppBar, Grid, Icon, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import breakpoints from "assets/theme/base/breakpoints";
import CodeSnippetContent from "ui-components/TutorialContent";

function CodeSnippet() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      setTabsOrientation(window.innerWidth < breakpoints.values.sm ? "vertical" : "horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const tabLabels = ["Java", "Java Script", "Python", "PHP", "Ruby", "GO", "cURL"];

  return (
    <MDBox position="relative">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={14} sm={10} mx={3}>
          <AppBar position="static">
            <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
              {tabLabels.map((label, index) => (
                <Tab
                  key={index}
                  label={label}
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      {/* Adjust icon based on the label, or use TableView for all */}
                      {label === "App" ? "home" : label === "Message" ? "email" : "settings"}
                    </Icon>
                  }
                  color="info"
                />
              ))}
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      <Grid item xs={12} md={14} sm={10} mx={3}>
        <CodeSnippetContent tabName={tabLabels[tabValue]} tabValue={tabValue} />
      </Grid>
    </MDBox>
  );
}

export default CodeSnippet;
