// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function UserProfileCard({ color, username, email, message, icon, endDate }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {email}
          </MDTypography>
          <MDTypography variant="h4"> Your email</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pb={2} pt={2}>
        <MDBox px={4} pt={0.5} display="flex" alignItems="center">
          <MDTypography variant="button" fontWeight="medium" color="text">
            Welcome, {username}!
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={2} px={2}>
        <MDTypography component="p" variant="button" color="text" display="flex">
          On &nbsp;
          <MDTypography component="span" variant="button" fontWeight="bold" color="success">
            {endDate}
          </MDTypography>
          &nbsp; {message}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of UserProfileCard
UserProfileCard.defaultProps = {
  color: "info",
};

// Typechecking props for the UserProfileCard
UserProfileCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  username: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.string,
  endDate: PropTypes.string,
  icon: PropTypes.node,
};

export default UserProfileCard;
