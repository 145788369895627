// UsageInfoModel.js

class UsageInfoModel {
  constructor(data) {
    this.dailyUsage = data.dailyUsage.map((usage) => ({
      date: new Date(usage.date),
      count: usage.count,
    }));

    this.usageLimit = {
      maxRequest: data.usageLimit.maxRequest,
      usedRequest: data.usageLimit.usedRequest,
      frequency: data.usageLimit.frequency,
    };
  }
}

export default UsageInfoModel;
