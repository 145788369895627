import { BrowserRouter } from "react-router-dom";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AmplifyProvider>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </AmplifyProvider>
  </React.StrictMode>
);
