import { useState, useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";
import routes from "routes";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setMiniSidenav, setLayout } from "context";
import Sidenav from "layouts/Sidenav";
import Footer from "layouts/Footer";
import { ThemeProvider } from "styled-components";
import theme from "assets/theme";
import { CssBaseline } from "@mui/material";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const { pathname } = useLocation();
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <MDBox py={1}>
        <MDBox
          sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
            p: 3,
            position: "relative",

            [breakpoints.up("xl")]: {
              marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
              top: miniSidenav ? pxToRem(50) : pxToRem(50),
              transition: transitions.create(["margin-left", "margin-right"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
              }),
            },
          })}
        >
          <Sidenav
            color={sidenavColor}
            brand=""
            brandName="MMP Labs"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />

          {children}
          <Footer isDashboard={true} />
        </MDBox>
      </MDBox>
    </ThemeProvider>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
