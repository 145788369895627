import CoverLayout from "layouts/CoverLayout";
import SubscriptionCheckout from "ui-components/Stripe/SubscriptionCheckout";

function MainCheckOut() {
  const title = "Payment";
  const description =
    "The provided sample integration code demonstrates how to utilize API keys for interacting with the Geography API. It showcases making an authenticated GET request to retrieve data from the API.";
  return (
    <CoverLayout title={title} description={description}>
      <SubscriptionCheckout />
    </CoverLayout>
  );
}

export default MainCheckOut;
