import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import PropTypes from "prop-types";
import styled from "styled-components";

const ResponsiveSwaggerUI = styled(SwaggerUI)`
  max-width: 100%;

  /* Additional styles for better mobile display */
  .swagger-ui {
    padding: 10px;

    .opblock {
      margin-bottom: 20px;
    }

    .opblock-summary {
      flex-direction: row !important;
      align-items: center;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 10px;

      .opblock-summary-path {
        flex: 1;
      }

      .opblock-summary-method {
        margin-left: 10px;
      }
    }
  }
`;

const SwaggerUIConfig = ({ swaggerUrl }) => {
  return <ResponsiveSwaggerUI url={swaggerUrl} />;
};

SwaggerUIConfig.propTypes = {
  swaggerUrl: PropTypes.string.isRequired,
};

export default SwaggerUIConfig;
