/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDBox from "components/MDBox";
import DashboardCoverLayout from "layouts/DashboardCoverLayout";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import RequireAuth from "layouts/authentication/RequireAuth";
import Notes from "ui-components/releaseNote/Notes";

function DashboardReleaseNotes() {
  return (
    <RequireAuth>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <DashboardCoverLayout profile={false}>
          <Notes />
        </DashboardCoverLayout>
      </DashboardLayout>
    </RequireAuth>
  );
}

export default DashboardReleaseNotes;
