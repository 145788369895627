import MDBox from "components/MDBox";
import DashboardCoverLayout from "layouts/DashboardCoverLayout";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import RequireAuth from "layouts/authentication/RequireAuth";
import CodeSnippet from "ui-components/CodeSnippet";

function DashboardTutorial() {
  const title = "CODE SNIPPET";
  const description =
    "The provided sample integration code demonstrates how to utilize API keys for interacting with the Geography API. It showcases making an authenticated GET request to retrieve data from the API.";
  return (
    <RequireAuth>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <DashboardCoverLayout profile={false}>
          <CodeSnippet />
        </DashboardCoverLayout>
      </DashboardLayout>
    </RequireAuth>
  );
}

export default DashboardTutorial;
