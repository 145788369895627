import CoverLayout from "layouts/CoverLayout";
import WhyUs from "ui-components/WhyUs";

function MainWhyUs() {
  return (
    <CoverLayout shrinkCardWidth={false}>
      <WhyUs />
    </CoverLayout>
  );
}

export default MainWhyUs;
