import React from "react";
import { CardMedia, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import image from "assets/images/lib_globe.jpeg";
import Section from "../Sections/Section";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function Usecases() {
  const imageStyles = {
    width: "100%",
    aspectRatio: { xs: "16/9", md: "1/1" }, // Adjust the aspect ratio as needed
    objectFit: "cover",
    borderRadius: "xl",
  };

  return (
    <Section
      title="Key Use Cases"
      description="Geography APIs have a wide range of use cases across industries and applications. Some common use cases include"
      color="dark"
      bgColor="white"
    >
      {/* Top Section */}
      <MDBox width="100%" mx="auto">
        {/* First Nested Section - Static Image */}
        <Grid container spacing={5} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={5}>
            <MDBox
              position="relative"
              width="100.25%"
              shadow="xl"
              borderRadius="xl"
              sx={{ height: "100%" }}
            >
              <CardMedia src={image} component="img" title="" sx={imageStyles} />
            </MDBox>
          </Grid>

          {/* Second Nested Section - Column List of Key Use Cases */}
          <Grid item xs={12} md={6}>
            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <Icon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { info } }) => info.main,
                  mt: -0.5,
                }}
              >
                done
              </Icon>
              <MDBox px={2}>
                <MDTypography variant="button" fontWeight="regular" color="info">
                  <strong>Rich Country Data:</strong>
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text" paragraph>
                  Our APIs offer an extensive collection of up-to-date information for every country
                  on Earth. From basic details like country names, codes, and capitals to more
                  in-depth data like population, languages spoken, time zones, and currencies –
                  we’ve got you covered!
                </MDTypography>
              </MDBox>
            </MDBox>

            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <Icon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { info } }) => info.main,
                  mt: -0.5,
                }}
              >
                done
              </Icon>
              <MDBox px={2}>
                <MDTypography variant="button" fontWeight="regular" color="info">
                  <strong>Geographical Insights:</strong>
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text" paragraph>
                  Explore the geographic wonders of each country with our APIs. Access latitude,
                  longitude, bordering countries, and even detailed maps to create mesmerizing
                  visualizations or location-based services.
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <Icon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { info } }) => info.main,
                  mt: -0.5,
                }}
              >
                done
              </Icon>
              <MDBox px={2}>
                <MDTypography variant="button" fontWeight="regular" color="info">
                  <strong>Multilingual Support:</strong>
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text" paragraph>
                  Our APIs support multiple languages, enabling you to cater to a diverse global
                  audience. Language details, official names, and translations are at your
                  fingertips, ensuring your applications are truly inclusive.
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <Icon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { info } }) => info.main,
                  mt: -0.5,
                }}
              >
                done
              </Icon>
              <MDBox px={2}>
                <MDTypography variant="button" fontWeight="regular" color="info">
                  <strong>Comprehensive City Data:</strong>
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text" paragraph>
                  Access a wide range of information about cities worldwide, including population
                  statistics, geographic coordinates, time zones, and administrative divisions.
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <Icon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { info } }) => info.main,
                  mt: -0.5,
                }}
              >
                done
              </Icon>
              <MDBox px={2}>
                <MDTypography variant="button" fontWeight="regular" color="info">
                  <strong>Continent, Region, and Sub-Region:</strong>
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text" paragraph>
                  REST API is your gateway to an abundance of geographic information, all at your
                  fingertips. Whether you’re a travel enthusiast, a data analyst, or a developer
                  seeking to enhance your application with powerful geographic data, our API has you
                  covered!
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Section>
  );
}

export default Usecases;
