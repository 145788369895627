import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CheckIcon from "@mui/icons-material/Check";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import plansData from "data/plansData";
import { getPlanNameById } from "data/getPlanNameById";
import { setIsNewUser, setSelectedPlanName, useMaterialUIController } from "context";
import { checkout } from "ui-components/Checkout";

function Monetization({ paddingfromtop, pricingPage }) {
  const [controller, dispatch] = useMaterialUIController();
  const { planId, user } = controller;

  const navigate = useNavigate();
  const planOrder = ["Basic", "Pro", "Ultra", "Mega"];
  const [selectedPlan, setSelectedPlan] = useState("");
  const userEmail = user?.attributes?.email;
  const userName = user?.username;

  useEffect(() => {
    if (planId) setSelectedPlan(getPlanNameById(planId));
  }, [planId, dispatch]);

  const handleSubscription = (plan) => {
    {
      /*  if (plan === "Basic") {
      navigate("/dashboard");
    } else {
      setSelectedPlanName(dispatch, plan);
      setIsNewUser(dispatch, pricingPage);
      console.log("setIsNewUser>> ", pricingPage);
      checkout({ plan, navigate });
    }*/
    }
  };

  const getButtonLabel = (plan) => {
    if (!pricingPage) {
      const selectedPlanIndex = planOrder.indexOf(selectedPlan);
      const currentPlanIndex = planOrder.indexOf(plan.name);
      if (selectedPlanIndex < currentPlanIndex) {
        return "Upgrade";
      } else if (selectedPlanIndex == currentPlanIndex) {
        return "Subscribed";
      } else {
        return "Downgrade";
      }
    } else {
      return "Subscribe";
    }
  };

  const isDisable = (plan) => {
    const selectedPlanIndex = planOrder.indexOf(selectedPlan);
    const currentPlanIndex = planOrder.indexOf(plan.name);

    return !pricingPage && selectedPlanIndex === currentPlanIndex;
  };

  const getButtonColor = (plan) => {
    const selectedPlanIndex = planOrder.indexOf(selectedPlan);
    const currentPlanIndex = planOrder.indexOf(plan.name);

    if (!pricingPage) {
      return selectedPlanIndex === currentPlanIndex ? "dark" : "info";
    } else {
      return "info";
    }
  };

  const plans = plansData;

  return (
    <MDBox pt={paddingfromtop}>
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan) => (
          <Grid item xs={12} md={3} key={plan.name}>
            <Card
              className={`Monetization-card ${
                !pricingPage && selectedPlan === plan.name ? "selected" : ""
              }`}
              style={{
                backgroundColor: !pricingPage && selectedPlan === plan.name ? "" : "",
                color: !pricingPage && selectedPlan === plan.name ? "#1a73e8" : "", // Darken the text color for contrast
                border:
                  !pricingPage && selectedPlan === plan.name
                    ? "1px overlay #1a73e8"
                    : "2px solid #eee",
                boxShadow:
                  !pricingPage && selectedPlan === plan.name
                    ? "0 8px 16px rgba(73, 163, 241, 3)"
                    : "none",
                borderRadius: !pricingPage && selectedPlan === plan.name ? "16px" : "8px",
                padding: !pricingPage && selectedPlan === plan.name ? "24px" : "16px",
                transition: "background-color 0.3s, box-shadow 0.3s",
              }}
            >
              <MDBox
                mx={2}
                mt={-5}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                justifyContent="center"
                display="flex"
              >
                <MDBox p={0}>
                  <MDTypography variant="body2" color="white" mt={-1} px={6}>
                    {plan.name}
                  </MDTypography>
                  <MDTypography variant="h6" color="white">
                    <AttachMoneyIcon variant="h6" /> {plan.price} {plan.period}
                  </MDTypography>
                </MDBox>
              </MDBox>

              <MDBox px={0} py={2}>
                <MDBox p={0}>
                  <MDBox component="ul" display="flex" flexDirection="column" py={3} mb={1}>
                    {plan.features.map((feature, index) => (
                      <MDBox
                        key={index}
                        component="li"
                        display="flex"
                        alignItems="center"
                        py={0.5}
                        mb={0.5}
                      >
                        {typeof feature === "object" ? (
                          <>
                            <MDTypography variant="button" color="text" fontWeight="regular">
                              {feature.name}
                            </MDTypography>
                            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                              {feature.subFeatures.map((subFeature, subIndex) => (
                                <MDBox
                                  key={subIndex}
                                  component="li"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <MDTypography variant="button" color="text" fontWeight="regular">
                                    &nbsp;&nbsp; &nbsp;&nbsp;- {subFeature}
                                  </MDTypography>
                                </MDBox>
                              ))}
                            </MDBox>
                          </>
                        ) : (
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            <CheckIcon color="info" /> &nbsp;&nbsp;{feature}
                          </MDTypography>
                        )}
                      </MDBox>
                    ))}
                  </MDBox>
                  <MDBox p={2} mt="auto">
                    <MDButton
                      component="a"
                      href="https://rapidapi.com/mmplabsadm/api/geography4/"
                      target="_blank"
                      rel="noreferrer"
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Subscribe
                    </MDButton>
                  </MDBox>
                  {/* <MDBox p={2} mt={2}>
                    <MDButton
                      onClick={() => {
                        setSelectedPlan(plan.name);
                        handleSubscription(plan.name); // Call the handleSubscription function
                      }}
                      variant="gradient"
                      color={getButtonColor(plan)}
                      fullWidth
                      disabled={isDisable(plan)}
                    >
                      {getButtonLabel(plan)}
                    </MDButton>
                  </MDBox>*/}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MDBox>
  );
}

Monetization.defaultProps = {
  paddingfromtop: 3,
  pricingPage: false,
  action: {
    type: "upgrade",
    color: "info",
    route: "",
    label: "upgrade",
  },
};
Monetization.propTypes = {
  paddingfromtop: PropTypes.number.isRequired,
  pricingPage: PropTypes.bool,
};

export default Monetization;
