import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(
  "pk_test_51NtEC0B4JF4tf4WX1xTTmQHD3BUH3gtw9aaScQrWON41KvYqqQPdmhmYBZtF78Sn80JVdY0WH1TTzW1qPw1af1AW00XoD3fHkV"
);
const clientSecret =
  "sk_test_51NtEC0B4JF4tf4WX14gkIUPAqIUdn4VP1AjA1LPilyK8MF9MiRN49xvEQp2H66ZVKIxsu27tpAiJu04dyBPrZtZO00Q7Mfd5Ae";

const SubscriptionCheckout = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm clientSecret={clientSecret} />
    </Elements>
  );
};

export default SubscriptionCheckout;
