// @mui material components
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import TimelineItem from "layouts/Timeline/TimelineItem";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import { Link } from "react-router-dom";

function QuickStartGuide() {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Quickstart Guide
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <IntegrationInstructionsIcon color="info" />
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              3
            </MDTypography>{" "}
            quick steps to integrate
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={1}>
        <TimelineItem
          color="success"
          icon="key"
          title="Access Credentials"
          dateTime={
            <>
              Obtain access credentials, such as an API key or OAuth token, by registering on the
              API provider&apos;s platform{" "}
            </>
          }
        />
        <TimelineItem
          color="error"
          icon="search"
          title="Explore API Endpoints"
          dateTime={
            <>
              Review and identify the specific endpoints relevant to your application&apos;s
              requirements. For more details, please refer to our documentation{" "}
              <Link to="/dashboard/documentation">here</Link>.
            </>
          }
        />
        <TimelineItem
          color="info"
          icon="code"
          title="Integrate API into Your Application"
          dateTime={
            <>
              Implement logic to make and handle API request and responses. Explore our tutorial{" "}
              <Link to="/dashboard/tutorial">here</Link> for readily available code.
            </>
          }
        />
      </MDBox>
    </Card>
  );
}

export default QuickStartGuide;
