import MDBox from "components/MDBox";
import DashboardCoverLayout from "layouts/DashboardCoverLayout";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import RequireAuth from "layouts/authentication/RequireAuth";
import ContactUs from "ui-components/ContactUs";

function DashboardContactUs() {
  return (
    <RequireAuth>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2}>
          <DashboardCoverLayout profile={false}>
            <ContactUs />
          </DashboardCoverLayout>
        </MDBox>
      </DashboardLayout>
    </RequireAuth>
  );
}

export default DashboardContactUs;
