// DashboardModel.js

import PlanDetailModel from "./PlanDetailModel";
import UsageInfoModel from "./UsageInfoModel";

class DashboardModel {
  constructor(data) {
    this.planDetail = data.planDetail ? new PlanDetailModel(data.planDetail) : null;
    this.apiKey = data.apiKey || null;
    this.usageInfo = data.usageInfo ? new UsageInfoModel(data.usageInfo) : null;
  }
}

export default DashboardModel;
