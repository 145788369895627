import MDBox from "components/MDBox";
import DashboardCoverLayout from "layouts/DashboardCoverLayout";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import RequireAuth from "layouts/authentication/RequireAuth";
import TermsAndConditions from "ui-components/TermsAndConditions";

function DashboardTermOfService() {
  return (
    <RequireAuth>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <DashboardCoverLayout profile={false}>
          <TermsAndConditions />
        </DashboardCoverLayout>
      </DashboardLayout>
    </RequireAuth>
  );
}

export default DashboardTermOfService;
