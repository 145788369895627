const codeSnippets = [
  // Code snippet for Java tab
  `     OkHttpClient client = new OkHttpClient().newBuilder().build();
     Request request = new Request.Builder()
         .url("https://geography4.p.rapidapi.com/apis/geography/v1/country")
         .method("GET", null)
         .addHeader("X-RapidAPI-Key", "API-KEY")
         .addHeader("X-RapidAPI-Host", "geography4.p.rapidapi.com")
         .build();
     Response response = client.newCall(request).execute();
    `,
  // Code snippet for JavaScript tab
  `      var settings = {
        "url": "https://geography4.p.rapidapi.com/apis/geography/v1/country",
        "method": "GET",
        "timeout": 0,
        "headers": {
          "X-RapidAPI-Key": "API-KEY",
          "X-RapidAPI-Host": "geography4.p.rapidapi.com"
        },
      };
       
      $.ajax(settings).done(function (response) {
        console.log(response);
      });
    `,
  // Code snippet for Python tab
  `    import requests
     
    url = "https://geography4.p.rapidapi.com/apis/geography/v1/country"
     
    payload={}
    headers = {
      'X-RapidAPI-Key': 'API-KEY',
      'X-RapidAPI-Host': 'geography4.p.rapidapi.com',
    }
     
    response = requests.request("GET", url, headers=headers, data=payload)
     
    print(response.text)
    `,
  // Code snippet for PHP tab
  `    <?php
     $curl = curl_init();
     curl_setopt_array($curl, array(
       CURLOPT_URL => 'https://geography4.p.rapidapi.com/apis/geography/v1/country',
       CURLOPT_CUSTOMREQUEST => 'GET',
       CURLOPT_HTTPHEADER => array(
         'X-RapidAPI-Key: API-KEY',
         'X-RapidAPI-Host: geography4.p.rapidapi.com'
       ),
     ));
      
     $response = curl_exec($curl);
      
     curl_close($curl);
     echo $response;
     `,
  // Code snippet for Ruby tab
  `    require "uri"
    require "net/http"
     
    url = URI("https://geography4.p.rapidapi.com/apis/geography/v1/country")
     
    http = Net::HTTP.new(url.host, url.port);
    request = Net::HTTP::Get.new(url)
    request["X-RapidAPI-Key"] = "API-KEY"
    request["X-RapidAPI-Host"] = "geography4.p.rapidapi.com"
     
    response = http.request(request)
    puts response.read_body
    `,
  // Code snippet for GO tab
  `     package main
     import (
       "fmt"
       "net/http"
       "io/ioutil"
     )
      
     func main() {
      
       url := "https://geography4.p.rapidapi.com/apis/geography/v1/country"
       method := "GET"
      
       client := &http.Client {
       }
       req, err := http.NewRequest(method, url, nil)
      
       if err != nil {
         fmt.Println(err)
         return
       }
       req.Header.Add("X-RapidAPI-Key", "API-KEY")
       req.Header.Add("X-RapidAPI-Host", "geography4.p.rapidapi.com")
       res, err := client.Do(req)
       if err != nil {
         fmt.Println(err)
         return
       }
       defer res.Body.Close()
      
       body, err := ioutil.ReadAll(res.Body)
       if err != nil {
         fmt.Println(err)
         return
       }
       fmt.Println(string(body))
     }
     }
     `,
  // Code snippet for cURL tab
  ` curl "https://geography4.p.rapidapi.com/apis/geography/v1/country?X-RapidAPI-Key=API-KEY&X-RapidAPI-Host=geography4.p.rapidapi.com"
    `,
];

export default codeSnippets;
