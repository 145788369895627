// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import ProfileInfoCard from "Cards/ProfileInfoCard";

// Overview page components

import RequireAuth from "layouts/authentication/RequireAuth";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardCoverLayout from "layouts/DashboardCoverLayout";
import { useMaterialUIController } from "context";

function Overview() {
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;

  return (
    <RequireAuth>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <DashboardCoverLayout>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={4}>
                <ProfileInfoCard
                  title="profile information"
                  description=""
                  info={{
                    fullName: user ? user.attributes.user : "Loading...",
                    mobile: user ? user.attributes.mobile : "Loading...",
                    email: user ? user.attributes.email : "Loading...",
                    location: user ? user.attributes.location : "Loading...",
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/CreativeTim/",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/creativetim",
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/creativetimofficial/",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                />
              </Grid>
            </Grid>
          </MDBox>
        </DashboardCoverLayout>
      </DashboardLayout>
    </RequireAuth>
  );
}

export default Overview;
