import React from "react";
import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

function Section({ title, children, description, color, bgColor }) {
  return (
    <MDBox
      elevation={3}
      bgColor={bgColor}
      sx={{ p: 3 }}
      width="calc(100% - 3rem)"
      borderRadius="xl"
      alignItems="center"
      mx={3}
      my={3}
      display="flex"
    >
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={11}>
          <MDBox textAlign="center">
            <MDTypography variant="h2" color={color} size="large">
              {title}
            </MDTypography>
            <MDTypography variant="caption" color="dark" size="small" paragraph>
              {description}
            </MDTypography>
          </MDBox>
        </Grid>
        <Divider sx={{ margin: 2 }} light={true} width="100vh" />
        {/* Section Content */}
        <Grid item xs={11}>
          <MDBox width="calc(100% - 2rem)" borderRadius="xl" alignItems="center">
            {children}
          </MDBox>
          {/* Add your section content here */}
        </Grid>
      </Grid>
    </MDBox>
  );
}

Section.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  children: PropTypes.node,
};
export default Section;
