// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import ReportsLineChart from "charts/ReportsLineChart";

// Data
import reportsLineChartData from "./data/reportsLineChartData";

import ApiKeyInfoCard from "./components/ApiKeyInfoCard";
import PlanUsageCard from "Cards/PlanUsageCard";
import UserProfileCard from "Cards/UserProfileCard";
import QuickStartGuide from "./components/QuickStartGuide";
import DashboardLayout from "layouts/DashboardLayout";
import RequireAuth from "layouts/authentication/RequireAuth";
import { useEffect, useState } from "react";
import { fetchDashboardInfo } from "service/apis";
import UsageInfoData from "./data/UsageInfoData";
import { getPlanNameById } from "data/getPlanNameById";
import { useMaterialUIController, setPlanId } from "context";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);

  const [controller, dispatch] = useMaterialUIController();
  const { user, planId } = controller;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dashboardInfo = await fetchDashboardInfo();
        const planId = dashboardInfo?.planDetail?.planId;

        setDashboardData(dashboardInfo);
        setPlanId(dispatch, planId);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, [dispatch]); // Empty dependency array to mimic componentDidMount behavior

  if (!dashboardData) {
    // Data is still loading
    return (
      <RequireAuth>
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox py={3}>
            Loading up your account. Please wait while we prepare your dashboard.
          </MDBox>
        </DashboardLayout>
      </RequireAuth>
    );
  }
  const sales = UsageInfoData(dashboardData.usageInfo);

  const usedRequest = dashboardData?.usageInfo?.usageLimit?.usedRequest;
  const maxRequest = dashboardData?.usageInfo?.usageLimit?.maxRequest;
  const frequency = dashboardData?.usageInfo?.usageLimit?.frequency;
  const planName = getPlanNameById(planId);
  const planEndDate = dashboardData?.planDetail?.endDate;

  var formattedDate = "";
  if (planEndDate) {
    const endDate = new Date(planEndDate);

    formattedDate = endDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }

  const message =
    "Basic" === planName
      ? "your free subscription will be reset"
      : "your " + planName + "subscription will be rereset";

  return (
    <RequireAuth>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ApiKeyInfoCard apiKey={dashboardData ? dashboardData.apiKey : "Loading ..."} />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <UserProfileCard
                  color="primary"
                  icon="person_add"
                  username={user?.username}
                  email={user?.attributes?.email}
                  message={message}
                  endDate={formattedDate}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <PlanUsageCard
                  icon="leaderboard"
                  color="success"
                  title={
                    dashboardData && dashboardData.planDetail && dashboardData.planDetail.planId
                      ? getPlanNameById(dashboardData.planDetail.planId)
                      : "Loading ..."
                  }
                  usedRequest={usedRequest}
                  maxRequest={maxRequest}
                  frequency={frequency}
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={4.5}>
            <Grid container spacing={4.5}>
              <Grid item xs={12} md={10} lg={8}>
                <MDBox mt={3}>
                  <ReportsLineChart
                    color="success"
                    title="Current month daily usage"
                    description={<>Number of requests per day.</>}
                    date="updated 4 min ago"
                    chart={sales}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <QuickStartGuide />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </RequireAuth>
  );
}

export default Dashboard;
