import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import Section from "../Sections/Section";

import StorageIcon from "@mui/icons-material/Storage";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";

import DefaultInfoCard from "Cards/DefaultInfoCard";
function Features() {
  return (
    <Section
      title="Geography APIs Key Feature’s"
      description="Are you looking for a powerful tool to integrate geographical data into your applications? Look no further! Our Geography REST API provides developers with seamless access to a vast array of geographical information, enabling you to create innovative applications."
      color="success"
    >
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon={<StorageIcon />}
                title="Geographical Data"
                description="Access geographical data, such as latitude and longitude coordinates, area, languages spoken, currency & many more"
                action={{
                  type: "internal",
                  route: "/documentation",
                  color: "info",
                  label: "Read more",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon="search"
                title="Flexible Queries"
                description="Tailor your requests to obtain the specific information you need. Make use of fields query parameter and get what interest you"
                action={{
                  type: "internal",
                  route: "/documentation",
                  color: "info",
                  label: "Read more",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon={<AccessTimeFilledIcon />}
                title="Time Zone Data"
                description="Access information about time zones associated with countries, including GMT offsets and daylight saving time details"
                value="+$2000"
                action={{
                  type: "internal",
                  route: "/documentation",
                  color: "info",
                  label: "Read more",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon={<DeveloperBoardIcon />}
                title="Developer Tools"
                description="SDKs, documentation, code samples, and resources to assist developers in implementing and troubleshooting geographical features in their applications"
                action={{
                  type: "internal",
                  route: "/documentation",
                  color: "info",
                  label: "Read more",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon={<ThumbUpAltIcon />}
                title="Data Accuracy"
                description="Our API’s provide data accuracy by sourcing information from reliable and reputable sources, ensuring users have access to trustworthy geographical data for their applications."
                action={{
                  type: "internal",
                  route: "/documentation",
                  color: "info",
                  label: "Read more",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                icon={<CloudCircleIcon />}
                title="Scalability and Performance"
                description="Our API's handle high volumes of requests with fast response times, scalable infrastructure and deliver optimal performance for data retrieval and processing. "
                action={{
                  type: "internal",
                  route: "/documentation",
                  color: "info",
                  label: "Read more",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Section>
  );
}

export default Features;
