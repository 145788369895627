// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import { Tooltip } from "@mui/material";

function PlanProgress({ usedRequest, maxRequest, frequency }) {
  return (
    <MDBox px={4} pt={0.5} display="flex" alignItems="center">
      <MDTypography variant="button" fontWeight="medium" color="text">
        0
      </MDTypography>
      <MDBox width="20rem" px={1}>
        <Tooltip title={usedRequest} placement="top">
          <MDProgress
            value={(usedRequest / maxRequest) * 100}
            color="success"
            variant="gradient"
            label={false}
          />
        </Tooltip>
      </MDBox>
      <MDTypography variant="button" fontWeight="medium" color="text">
        {maxRequest}
      </MDTypography>
    </MDBox>
  );
}

function PlanUsageCard({ color, title, usedRequest, maxRequest, frequency, icon }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            Your Plan
          </MDTypography>
          <MDTypography variant="h4">{title}</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pb={2} pt={2}>
        <PlanProgress usedRequest={usedRequest} maxRequest={maxRequest} frequency={frequency} />
      </MDBox>
      <Divider />
      <MDBox pb={2} px={2}>
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="success">
            {(usedRequest / maxRequest) * 100}%
          </MDTypography>
          &nbsp;qouta used this month
        </MDTypography>
      </MDBox>
    </Card>
  );
}
PlanUsageCard;

PlanProgress.propTypes = {
  maxRequest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  usedRequest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  frequency: PropTypes.string,
};

// Typechecking props for the PlanUsageCard
PlanUsageCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  maxRequest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  usedRequest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  frequency: PropTypes.string,

  icon: PropTypes.node.isRequired,
};

export default PlanUsageCard;
