import PropTypes from "prop-types";
import { CardMedia, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ImageCard({ leftImage, image, title, description }) {
  const imageStyles = {
    width: "75%",
    aspectRatio: { xs: "16/9", md: "1/1" },
    objectFit: "cover",
    borderRadius: "xl",
  };
  return leftImage ? (
    <MDBox mx="auto">
      {/* First Nested Section - Static Image */}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={5}>
          <MDBox>
            <CardMedia src={image} component="img" title="" sx={imageStyles} />
          </MDBox>
        </Grid>

        {/* Second Nested Section - Column List of Key Use Cases */}
        <Grid item xs={12} md={5}>
          <MDBox pt={{ xs: 2, md: 2 }} display="flex" alignItems="center" lineHeight={2}>
            <MDBox>
              <MDTypography variant={{ xs: "h4", md: "h1" }} color="info">
                <strong>{title}</strong>
              </MDTypography>
              <MDTypography
                pt={{ xs: 2, md: 2 }}
                variant="body2"
                fontWeight="regular"
                color="text"
                paragraph
              >
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  ) : (
    <MDBox mx="auto">
      {/* First Nested Section - Static Image */}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* Second Nested Section - Column List of Key Use Cases */}
        <Grid item xs={12} md={5}>
          <MDBox pt={{ xs: 2, md: 2 }} display="flex" alignItems="center" lineHeight={0}>
            <MDBox>
              <MDTypography variant={{ xs: "h4", md: "h1" }} color="info">
                <strong>{title}</strong>
              </MDTypography>
              <MDTypography
                pt={{ xs: 2, md: 2 }}
                variant="body2"
                fontWeight="regular"
                color="text"
                paragraph
              >
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <MDBox>
            <CardMedia src={image} component="img" title="" sx={imageStyles} />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

ImageCard.propTypes = {
  leftImage: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ImageCard;
