export default function UsageInfoData(usageInfo) {
  const sales = {
    labels: [],
    datasets: { label: "Request count ", data: [] },
  };

  usageInfo.dailyUsage.forEach((entry) => {
    const date = new Date(entry.date);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`; // Month is zero-based
    const count = entry.count;

    sales.labels.push(formattedDate);
    sales.datasets.data.push(count);
  });
  return sales;
}
