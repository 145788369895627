import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";

const PaymentForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment(clientSecret, {
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://localhost:3000/main/dashboard",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error.message);
      console.log(result.error.message);
    } else {
      console.log(result.paymentMethod);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {error && <div>{error}</div>}
      <button type="submit" disabled={!stripe}>
        Subscribe
      </button>
    </form>
  );
};

PaymentForm.propTypes = {
  clientSecret: PropTypes.string,
};

export default PaymentForm;
