import { loadStripe } from "@stripe/stripe-js";
import { Auth } from "aws-amplify";
import { getStripIdByName } from "data/getStripIdByName";
import isUserAuthenticated from "layouts/authentication/CheckAuthenticationStatus";

export const checkout = async ({ plan, navigate }) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const userEmail = user?.attributes?.email;
    const userName = user?.username;
    console.log("????" + userName);
    console.log("????" + userEmail);
    const strip_plan_id = getStripIdByName(plan);
    const stripe = await loadStripe(
      "pk_test_51NtEC0B4JF4tf4WX1xTTmQHD3BUH3gtw9aaScQrWON41KvYqqQPdmhmYBZtF78Sn80JVdY0WH1TTzW1qPw1af1AW00XoD3fHkV"
    );
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: String(strip_plan_id), quantity: 1 }],
      mode: "subscription",
      customerEmail: userEmail,
      successUrl: "http://localhost:3000/main/dashboard",
      cancelUrl: "http://localhost:3000/main/dashboard",
      clientReferenceId: userName,
    });

    if (error) {
      console.log(error);
    }
  } catch {
    console.log("navigate to login ");
    navigate("/login");
  }
};
