/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Icon from "@mui/material/Icon";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import Home from "./pages/main/Home";
import Pricing from "pages/main/Pricing";
import Documentation from "pages/main/Documentation";
import Tutorial from "pages/main/tutorial";
import ReleaseNotes from "pages/main/ReleaseNotes";
import TermOfService from "pages/main/TermsOfService";
import Profile from "pages/main/profile";
import Dashboard from "pages/dashboard";
import DashboardDocumentation from "pages/dashboard/pages/documentation";
import Subscription from "pages/dashboard/pages/subscription";
import MainContactUs from "pages/main/MainContactUs";
import DashboardContactUs from "pages/dashboard/pages/ContactUs";

import NotesIcon from "@mui/icons-material/Notes";
import CodeIcon from "@mui/icons-material/Code";
import HelpIcon from "@mui/icons-material/Help";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import RuleIcon from "@mui/icons-material/Rule";
import ApiIcon from "@mui/icons-material/Api";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PaymentIcon from "@mui/icons-material/Payment";
import Login from "pages/main/login";
import DashboardTermOfService from "pages/dashboard/pages/TermsOfService";
import DashboardTutorial from "pages/dashboard/pages/tutorial";
import DashboardReleaseNotes from "pages/dashboard/pages/ReleaseNotes";
import DashboardWhyUs from "pages/dashboard/pages/WhyUs";
import MainWhyUs from "pages/main/WhyUs";
import MainCheckOut from "pages/main/payement/MainCheckOut";

const routes = [
  {
    type: "main",
    name: "Landing Page",
    key: "landing-page",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/",
    component: <Home />,
  },
  {
    type: "main",
    name: "Login",
    key: "login",
    icon: <VpnKeyIcon />,
    route: "/login",
    component: <Login />,
  },
  {
    type: "dashboard",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "main",
    name: "Pricing",
    key: "pricing",
    icon: <MonetizationOnIcon />,
    route: "/pricing",
    component: <Pricing />,
  },

  {
    type: "dashboard",
    name: "Your Subscription",
    key: "subscription",
    icon: <MonetizationOnIcon />,
    route: "/dashboard/subscription",
    component: <Subscription />,
  },

  {
    type: "main",
    name: "Documentation",
    key: "documentation",
    icon: <ApiIcon />,
    route: "/documentation",
    component: <Documentation />,
  },
  {
    type: "dashboard",
    name: "Documentation",
    key: "documentation",
    icon: <ApiIcon />,
    route: "/dashboard/documentation",
    component: <DashboardDocumentation />,
  },
  {
    type: "main",
    name: "Tutorial",
    key: "tutorial",
    icon: <CodeIcon />,
    route: "/tutorial",
    component: <Tutorial />,
  },
  {
    type: "main",
    name: "Release Notes",
    key: "releaseNotes",
    icon: <NotesIcon />,
    route: "/release-notes",
    component: <ReleaseNotes />,
  },
  {
    type: "main",
    name: "Contact Us",
    key: "contact-us",
    icon: <ContactPageIcon />,
    route: "/contact-us",
    component: <MainContactUs />,
  },
  {
    type: "main",
    name: "Why us",
    key: "why-us",
    icon: <HelpIcon />,
    route: "/why-us",
    component: <MainWhyUs />,
  },
  {
    type: "main",
    name: "Checkout",
    key: "checkout",
    icon: <PaymentIcon />,
    route: "/checkout",
    component: <MainCheckOut />,
  },
  {
    type: "main",
    name: "Term Of Service",
    key: "term-of-service",
    icon: <RuleIcon />,
    route: "/term-of-service",
    component: <TermOfService />,
  },
  {
    type: "footer",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/dashboard/profile",
    component: <Profile />,
  },
  {
    type: "footer",
    name: "Why us",
    key: "why-us",
    icon: <HelpIcon />,
    route: "/dashboard/why-us",
    component: <DashboardWhyUs />,
  },
  {
    type: "footer",
    name: "Term Of Service",
    key: "term-of-service",
    icon: <RuleIcon />,
    route: "/dashboard/term-of-service",
    component: <DashboardTermOfService />,
  },
  {
    type: "dashboard",
    name: "Tutorial",
    key: "tutorial",
    icon: <CodeIcon />,
    route: "/dashboard/tutorial",
    component: <DashboardTutorial />,
  },
  {
    type: "dashboard",
    name: "Release Notes",
    key: "releaseNotes",
    icon: <NotesIcon />,
    route: "/dashboard/release-notes",
    component: <DashboardReleaseNotes />,
  },
  {
    type: "dashboard",
    name: "Contact Us",
    key: "contact-us",
    icon: <ContactPageIcon />,
    route: "/dashboard/contact-us",
    component: <DashboardContactUs />,
  },
];

export default routes;
