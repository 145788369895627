// Import necessary components and features
import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import image from "assets/images/cover.jpg";
import { Divider } from "@mui/material";
import Footer from "layouts/Footer";
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";
import { Link } from "react-router-dom";
import KeyFeatures from "ui-components/Features/KeyFeatures";

function LandingPage({ children }) {
  return (
    <>
      <DefaultNavbar
        action={{
          type: "external",
          route: "https://creative-tim.com/product/material-dashboard-react",
          label: "free download",
        }}
        transparent
        light={true}
      />
      {/* Hero Section */}
      <MDBox
        width="calc(100% - 3rem)"
        borderRadius="xl"
        alignItems="center"
        justifyContent="center"
        display="flex"
        mx={3}
        my={3}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed", // Add this line
        }}
      >
        <MDBox px={1} width="100%" minHeight={{ xs: "50vh", md: "70vh" }} mx="auto">
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <MDBox textAlign="center" p={{ xs: 3, md: 14 }} mt={{ xs: 10, md: 20 }}>
                {/* Added mt property for margin-top */}
                <MDTypography variant="h2" color="white" size="large">
                  Explore the World with Our Country API
                </MDTypography>
                <Divider orientation="horizontal" />
                <MDTypography variant="body2" color="white" paragraph>
                  Unleash the potential of our powerful APIs. Unlock endless possibilities with our
                  APIs and more. Seamlessly integrate into your applications and take your products
                  and services to new heights by harnessing the rich features and accuracy of our
                  API and.
                </MDTypography>
                <MDButton
                  component={Link}
                  to="/pricing"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Get Started
                </MDButton>
              </MDBox>
            </Grid>
            {/*<Grid item xs={12} md={6}>
              <KeyFeatures />
      </Grid>*/}
          </Grid>
        </MDBox>
      </MDBox>

      {/* Main Content Section */}
      <MDBox mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            {children}
          </Grid>
        </Grid>
      </MDBox>

      {/* Footer Section */}
      <MDBox
        width="calc(100% - 2rem)"
        minHeight="20vh"
        borderRadius="xl"
        alignItems="center"
        mx={2}
        my={2}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.7),
              rgba(gradients.dark.state, 0.4)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            {/* Footer component */}
            <Footer light={true} />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

// Typechecking props for the LandingPage
LandingPage.propTypes = {
  children: PropTypes.node,
};

export default LandingPage;
