import swaggerFile from "assets/SwaggerFile/countries.yaml";
import MDBox from "components/MDBox";
import RequireAuth from "layouts/authentication/RequireAuth";
import DashboardCoverLayout from "layouts/DashboardCoverLayout";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import SwaggerUIConfig from "ui-components/SwaggerUIConfig";

function DashboardDocumentation() {
  return (
    <RequireAuth>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <DashboardCoverLayout profile={false}>
          <SwaggerUIConfig swaggerUrl={swaggerFile} />
        </DashboardCoverLayout>
      </DashboardLayout>
    </RequireAuth>
  );
}

export default DashboardDocumentation;
