import CoverLayout from "layouts/CoverLayout";
import Monetization from "ui-components/Monetization";

function Pricing() {
  const title = "OUR PRICING";

  return (
    <CoverLayout shrinkCardWidth={false}>
      <Monetization pricingPage={true} paddingfromtop={3} />
    </CoverLayout>
  );
}

export default Pricing;
