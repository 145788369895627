import { Divider, Grid, Icon, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ContactForm from "./ContactForm";

function ContactUs() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <MDBox width="100%" mx="auto" p={3}>
      <Grid container spacing={isSmallScreen ? 2 : 5} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <MDBox
            position="relative"
            width="100%"
            shadow="xl"
            borderRadius="xl"
            sx={{ height: "100%" }}
            p={3}
          >
            <MDTypography variant="h2" fontWeight="regular" color="info">
              <strong>Get In Touch With Us:</strong>
            </MDTypography>
            <Divider orientation="horizontal" />
            <MDTypography variant="body2" fontWeight="regular" color="text" paragraph>
              We would love to hear from you. Whether you have a question, feedback, or simply want
              to say hello, we’re here to assist you. Fill out the contact form or email us at{" "}
              <strong>support@mmplabs.com</strong>, and our dedicated team will get back to you as
              soon as possible. Your input is valuable to us, and we look forward to connecting with
              you.
            </MDTypography>
          </MDBox>
          <Grid
            container
            spacing={isSmallScreen ? 2 : 5}
            justifyContent="center"
            alignItems="center"
            py={3}
          >
            <Grid item xs={12} md={6}>
              <MDBox
                display="grid"
                justifyContent="center"
                alignItems="center"
                bgColor="info"
                color="white"
                width="4rem"
                height="4rem"
                shadow="md"
                borderRadius="lg"
                variant="gradient"
              >
                <Icon fontSize="default">email</Icon>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox
                display="flex"
                flexDirection="column" // Align items in a column
                pr={2}
              >
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Send Us A Message:
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  support@mmplabs.com
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
        {/*  <Grid item xs={12} md={6}>
          <MDBox
            position="relative"
            width="100%"
            shadow="xl"
            borderRadius="xl"
            sx={{ height: "100%" }}
            mt={isSmallScreen ? 3 : 0} // Add top margin on small screens
          >
            <ContactForm />
          </MDBox>
  </Grid>*/}
      </Grid>
    </MDBox>
  );
}

export default ContactUs;
