import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import RequireAuth from "layouts/authentication/RequireAuth";
import MDBox from "components/MDBox";

import DashboardCoverLayout from "../../../../layouts/DashboardCoverLayout";
import Monetization from "ui-components/Monetization";

function Subscription() {
  return (
    <RequireAuth>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <DashboardCoverLayout profile={false}>
          <Monetization paddingfromtop={4} />
        </DashboardCoverLayout>
      </DashboardLayout>
    </RequireAuth>
  );
}

export default Subscription;
