import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import continentImage from "assets/images/continent.jpg";
import cityImage from "assets/images/city.jpg";
import countryImage from "assets/images/country.png";
import DefaultProjectCard from "Cards/ProjectCards/DefaultProjectCard";
import Section from "../Sections/Section";
function WhatWeOffer() {
  return (
    <Section
      title="What We Offer"
      description="Geography APIs are categorized into hierarchical levels, providing developers access to data ranging from continents and countries to regions, provinces, and cities. This classification enables developers to retrieve specific geospatial information based on their application needs and create location-based functionalities with ease"
      color="success"
    >
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} xl={3} lg={4} p={4}>
          <MDBox mb={1.5}>
            <DefaultProjectCard
              image={continentImage}
              label="Service #1"
              title="Continent, Region & Sub-Region APIs"
              description="Continent, Region and Sub-Region APIs offer data about larger geographical areas across the globe."
              action={{
                type: "internal",
                route: "/documentation",
                color: "info",
                label: "Read more",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} xl={3} lg={4} p={4}>
          <MDBox mb={1.5}>
            <DefaultProjectCard
              image={countryImage}
              label="Service #2"
              title="Country APIs"
              description="Country APIs provide comprehensive data about countries, including details such as names, codes, capital cities, populations, timezones, currencies, languages, & more."
              action={{
                type: "internal",
                route: "/documentation",
                color: "info",
                label: "Read more",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} xl={3} lg={4} p={4}>
          <MDBox mb={1.5}>
            <DefaultProjectCard
              image={cityImage}
              label="Service #3"
              title="City APIs"
              description="Offers useful information about cities around the world. They offer information about cities’ names, codes, populations, geographical coordinates, timezones, & more."
              action={{
                type: "internal",
                route: "/documentation",
                color: "info",
                label: "Read more",
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
    </Section>
  );
}

export default WhatWeOffer;
