// components/Layout.js
import React from "react";
import LandingPage from "./LandingPage";
import WhatWeOffer from "ui-components/Offerings/WhatWeOffer";
import Usecases from "ui-components/UseCases/UseCases";
import Features from "ui-components/Features/Features";

export default function Home() {
  return (
    <LandingPage>
      <WhatWeOffer />
      <Usecases />
      <Features />
    </LandingPage>
  );
}
