// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import typography from "assets/theme/base/typography";
import DefaultNavbarLink from "layouts/Navbars/DefaultNavbar/DefaultNavbarLink";
import Breadcrumbs from "layouts/Breadcrumbs";

function Footer({ light, isDashboard }) {
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color={light ? "white" : "text"}
        fontSize={size.sm}
      >
        Copyright &copy; {new Date().getFullYear()},
        <MDBox component={Link} to="/" lineHeight={1} pl={{ xs: 0, lg: 1 }}>
          <MDTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
            MMP Labs &nbsp;
          </MDTypography>
        </MDBox>
        {/*<Link href="/" target="_blank">
          <MDTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
            &nbsp;MMP Labs&nbsp;
          </MDTypography>
        </Link> */}
        All rights reserved.
      </MDBox>
      <MDBox justifyContent="space-between" alignItems="center">
        <MDBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        >
          <MDBox component="li">
            <DefaultNavbarLink
              name="tutorial"
              route={isDashboard ? "/dashboard/tutorial" : "/tutorial"}
              light={light}
            />
          </MDBox>
          <MDBox component="li">
            <DefaultNavbarLink
              name="release Notes"
              route={isDashboard ? "/dashboard/release-notes" : "/release-notes"}
              light={light}
            />
          </MDBox>
          <MDBox component="li">
            <DefaultNavbarLink
              name="why us"
              route={isDashboard ? "/dashboard/why-us" : "/why-us"}
              light={light}
            />
          </MDBox>
          <MDBox component="li">
            <DefaultNavbarLink
              name="term of service"
              route={isDashboard ? "/dashboard/term-of-service" : "/term-of-service"}
              light={light}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
  isDashboard: PropTypes.bool,
};

export default Footer;
