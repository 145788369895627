import CoverLayout from "layouts/CoverLayout";
import swaggerFile from "assets/SwaggerFile/countries.yaml";
import SwaggerUIConfig from "ui-components/SwaggerUIConfig";

function Documentation() {
  return (
    <CoverLayout shrinkCardWidth={false}>
      <SwaggerUIConfig swaggerUrl={swaggerFile} />
    </CoverLayout>
  );
}

export default Documentation;
