import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Snackbar from "@mui/material/Snackbar";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";

import { useMaterialUIController } from "context";

function ApiKeyInfoCard({ apiKey }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const color = "info";

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Card>
      <MDBox p={2.5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h6" fontWeight="medium">
                Your API Access Key
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
                wordBreak: "break-all", // Allow words to break and wrap
                whiteSpace: "pre-wrap", // Preserve whitespace and wrap
              }}
            >
              <MDBox
                variant="gradient"
                bgColor={color}
                color={color === "light" ? "dark" : "white"}
                coloredShadow={color}
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
                p={2}
              >
                <Icon fontSize="medium" color="inherit">
                  key
                </Icon>
              </MDBox>
              <MDBox px={2} display="flex" alignItems="center" flexWrap="wrap" flexGrow={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  {apiKey}
                </MDTypography>
              </MDBox>
              <MDBox lineHeight={0} color={darkMode ? "white" : "dark"} ml={1}>
                <ClickAwayListener onClickAway={handleCloseSnackbar}>
                  <Tooltip title="Copy to Clipboard" placement="top">
                    <Icon
                      sx={{ cursor: "pointer" }}
                      fontSize="small"
                      onClick={handleCopyToClipboard}
                    >
                      content_copy
                    </Icon>
                  </Tooltip>
                </ClickAwayListener>
              </MDBox>
            </MDBox>
          </Grid>
          {/*
          <Grid item xs={12}>
            <MDBox px={2} display="flex" alignItems="center" justifyContent="top">
              <MDButton variant="gradient" color="info" size="small">
                <Icon sx={{ fontWeight: "bold" }}>key</Icon>
                &nbsp;&nbsp;Reset
              </MDButton>
            </MDBox>
            </Grid>*/}
        </Grid>
      </MDBox>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
      />
    </Card>
  );
}

ApiKeyInfoCard.propTypes = {
  apiKey: PropTypes.string.isRequired,
};

export default ApiKeyInfoCard;
