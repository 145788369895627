import { Grid, List, ListItem, ListItemText } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import termsAndConditions from "data/termsAndCondition";
import React from "react";

const renderContent = (content) => {
  return (
    <List>
      {content.map((section, index) => (
        <MDBox key={index}>
          {Object.entries(section).map(([subheading, subcontent], itemIndex) => {
            return (
              <React.Fragment key={itemIndex}>
                {subheading !== "" && (
                  <ListItem key={itemIndex}>
                    <ListItemText>
                      <MDTypography variant="h6">{subheading}</MDTypography>
                    </ListItemText>
                  </ListItem>
                )}

                <List>
                  {subcontent.map((subsection, subindex) => (
                    <ListItem key={subindex}>
                      <ListItemText>
                        <MDTypography pt={1} variant="body2" paragraph>
                          {subsection}
                        </MDTypography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </React.Fragment>
            );
          })}
        </MDBox>
      ))}
    </List>
  );
};

function TermsAndConditions() {
  return (
    <MDBox position="relative">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={14} sm={10} mx={3}>
          <MDBox p={4}>
            <MDTypography variant="h3" alignItems="center" textAlign="center">
              Terms and Conditions
            </MDTypography>
            <MDBox pt={2}>
              <MDTypography pt={1} variant="body2" paragraph>
                This Agreement governs your use of this website, as well as the applications and
                other online products and services provided by MMP Labs (“MMP Labs” or “we”). By
                accessing and using MMP Labs services, you acknowledge and agree to comply with the
                terms and provisions of this Agreement. Additionally, when using specific services,
                you are subject to any posted guidelines or rules applicable to those services. Your
                participation in this service indicates your acceptance of this Agreement. If you do
                not agree to these terms, please refrain from using this service. If you have any
                inquiries about this Agreement or our Services, please reach out to us through our
                contact form.
              </MDTypography>
            </MDBox>
            {termsAndConditions && termsAndConditions.length > 0 && (
              <MDBox mb={1.5}>
                {termsAndConditions.map((section, index) => (
                  <MDBox key={index}>
                    {Object.entries(section).map(([heading, content], index) => (
                      <MDBox key={index}>
                        <MDTypography pt={1} variant="h4" gutterBottom>
                          {heading}
                        </MDTypography>

                        {renderContent(content)}
                      </MDBox>
                    ))}
                  </MDBox>
                ))}
                {/*
                <List>
                  {termsAndConditions.map((term, index) => (
                    <ListItem key={index} sx={{ listStyleType: "disc" }} variant="body2">
                      <MDTypography variant="body2" color="info">
                        {" "}
                        {term}
                      </MDTypography>
                    </ListItem>
                  ))}
                  </List>*/}
              </MDBox>
            )}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default TermsAndConditions;
