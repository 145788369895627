import CoverLayout from "layouts/CoverLayout";
import ContactUs from "ui-components/ContactUs";

function MainContactUs() {
  return (
    <CoverLayout shrinkCardWidth={false}>
      <ContactUs />
    </CoverLayout>
  );
}

export default MainContactUs;
