import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StyledAccordion = styled(Accordion)({
  border: "1px solid #ddd",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: "#f3f3f3",
  borderRadius: "8px",
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: "16px",
});

const StyledTypography = styled(Typography)({
  fontWeight: "bold",
});

function ReleaseNotesAccordion({ version, features, bugFixes, summary, expand }) {
  return (
    <MDBox py={1}>
      <StyledAccordion defaultExpanded={expand}>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <MDBox>
            <StyledTypography variant="h6">Version: {version}</StyledTypography>
          </MDBox>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <MDBox>
            {features && features.length > 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <MDBox mb={1.5}>
                    {summary ? (
                      <Typography variant="body2">
                        <StyledTypography variant="gradient" color="info">
                          Summary:
                        </StyledTypography>{" "}
                        {summary}
                      </Typography>
                    ) : (
                      ""
                    )}
                    <StyledTypography variant="gradient" color="info">
                      Features:
                    </StyledTypography>
                  </MDBox>
                  <MDBox mb={1.5}>
                    <List>
                      {features.map((feature, index) => (
                        <ListItem key={index} sx={{ listStyleType: "disc" }} variant="body2">
                          <Typography variant="body2" color="info">
                            {" "}
                            <CheckCircleIcon color="info" /> {feature}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </MDBox>
                </Grid>
              </Grid>
            )}
            {bugFixes && bugFixes.length > 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <MDBox mb={1.5}>
                    <StyledTypography variant="subtitle1">Bug Fixes:</StyledTypography>
                  </MDBox>
                  <MDBox mb={1.5}>
                    <List>
                      {bugFixes.map((bugFix, index) => (
                        <ListItem key={index} sx={{ listStyleType: "disc" }}>
                          <Typography variant="body2" color="info">
                            {" "}
                            <CheckCircleIcon color="info" /> {bugFix}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </MDBox>
                </Grid>
              </Grid>
            )}
          </MDBox>
        </StyledAccordionDetails>
      </StyledAccordion>
    </MDBox>
  );
}

ReleaseNotesAccordion.propTypes = {
  version: PropTypes.string.isRequired,
  summary: PropTypes.string,
  features: PropTypes.node,
  bugFixes: PropTypes.node,
  expand: PropTypes.bool,
};

export default ReleaseNotesAccordion;
