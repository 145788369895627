import React from "react";
import ReleaseNotesAccordion from "./ReleaseNotesAccordion";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

function Notes() {
  const releaseData = [
    {
      version: "0.8.0",
      summary:
        "This release introduces whole new set of data with historical information which can be used for various trends and querying use-cases",
      features: [
        "Get latest as well as historical information about Fertility Ratio for a Country",
        "Get latest as well as historical information about Literarcy Rate for a Country",
        "Get latest as well as historical information about Gender Ratio for a Country",
        "The Country model introduces data about different major organizations a country is member of. A new field called ‘groups’ holds this information",
      ],
      expanded: true,
    },
    {
      version: "0.7.0",
      features: [
        "Latest GDP data added for countries. Now one can also search countries based on max and min value of GDP data and sort them by supported sorting fields",
      ],
      expanded: false,
    },
    {
      version: "0.6.1",
      features: [
        "All Country APIs now support sorting by different fields. See API documentation for list of supported fields. One can now sort in Ascending or Descending order",
      ],
      expanded: false,
    },
    {
      version: "0.6.0",
      summary:
        "This release comes with considerable amount of new functionalities which makes the suite of APIs much more convenient to use for various different use-cases",
      features: [
        "Get Countries query parameters are now made more explicit and they now support AND queries",
        "Search Countries based on FIFA assigned Country Codes",
        "Search Countries based on the TimeZone supported in that country",
        "Search all the neighbours of a country by providing the country code",
        "Search Countries based on the population. It is possible to search based on Min Population or Max Population. One can also search based on population range between Min and Max",
        "New Group of APIs are now introduced to provide the list of Continents, Regions and Sub-Regions",
      ],
      bugFixes: [
        "Providing empty value in fields query parameter, returned errror. The empty value is now handled and shouldn’t throw any unexpected error",
      ],
      expanded: false,
    },
    {
      version: "0.5.0",
      features: [
        "Search Countries by Capital – Search a country by its capital. The searches are case insensitive and by default provides like based search which means the subset string matches superset. If exact match is required, please pass query parameter “exactMatch=true”.",
        "List all countries within a continent – Now you can list all the countries within a Continent. Only exact match is supported. The searches are case insensitive",
        "Search countries by Currency – Search countries based on currencies used within that country. One can search the countries by providing currency code or currency name. The searches are case insensitive.",
        "Search Countries by IDD (International Direct Dialing) – Search countries using the IDD number assigned to the countries. The search should start with + symbol followed by the digits",
      ],
      expanded: false,
    },
    {
      version: "0.3.1",
      features: [
        "Search Countries by Name – Now include native names in your searches by adding query parameter “includeNativeName=true”.",
      ],
      expanded: false,
    },
    {
      version: "0.3.0",
      features: [
        "New targeted API introduced to search for Countries based on languages used in those countries",
        "Both language code or language name can be provided for searching",
        "The search is case insensitive and exact match",
        "The API supports Pagination and the default page size is 10 for all Basic Plan Users. The page size is virtually unlimited for Paid Plan users",
      ],
      expanded: false,
    },
    {
      version: "0.2.1",
      summary: "New targeted API introduced to search for Countries based on their names",
      features: [
        "This API provides convenience for users who want to have more control on search criteria. The API searches the name provided with all Common and Official names of the Countries and matches with any occurrence of the provided name in the list of available countries. If exact match is required, pass the query parameter “exactMatch=true”",
        "New feature 4The search is case insensitive",
        "The API supports Pagination and the default page size is 10 for all Basic Plan Users. The page size is virtually unlimited for Paid Plan users",
      ],
      bugFixes: ["Fixed the map reference for Indonesia"],
      expanded: false,
    },
    {
      version: "0.2.0",
      features: [
        "New API to browse information about various Cities across the world is added",
        "Fetch all the cities within a Country",
        "Get details of a city based on its Id",
      ],
      expanded: false,
    },
    {
      version: "0.1.1",
      summary:
        "This version of API focuses on providing information about Countries around the world with different search capabilities. The API provides a summarized view which can be used by clients who do not wish to provide detailed information about the country every time. This helps in saving transfer of large chunk of data over network, making the application processing faster. The API also provides the detailed view option via query parameter so that the details about the country is returned if required by the client application.",
      features: [
        "Get all Countries – This API provides information about all the countries across the world. One can search based on different fields of the country.",
        "Get A country – This API provides information about a country based on its ISO codes.",
      ],
      expanded: false,
    },
    // Add more release data as needed
  ];

  return (
    <MDBox position="relative">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={14} sm={10} mx={3}>
          <MDBox p={0}>
            <MDTypography variant="h6">Release Notes:</MDTypography>
          </MDBox>

          {releaseData.map((release, index) => (
            <ReleaseNotesAccordion
              key={index}
              version={release.version}
              features={release.features}
              bugFixes={release.bugFixes}
              summary={release.summary}
              expand={release.expanded}
            />
          ))}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Notes;
