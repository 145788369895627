const termsAndConditions = [
  {
    Eligibility: [
      {
        "Minors and Guardianship Responsibilities": [
          "If you are below 18 years old (or below the age of legal majority in your jurisdiction), you may only utilize our Services with the guidance and supervision of a parent or legal guardian who agrees to adhere to this Agreement. As a parent or legal guardian of a user under 18 (or under the age of legal majority), you assume full responsibility for the actions and behavior of the user concerning our Services. In the event that you access or use our Services on behalf of another individual or entity, you confirm that you have the authority to accept this Agreement on behalf of that person or entity. Furthermore, you assert that the person or entity in question is accountable to us in the event of any violation of this Agreement by either you or the aforementioned person or entity.",
        ],
      },
    ],
  },
  {
    "Account Usage and Security": [
      {
        "": [
          "It is your responsibility to ensure the security of your account and promptly inform us if you become aware or suspect unauthorized access to your account. If you allow others to use your account credentials, you are accountable for any actions performed by those users in relation to your account.",
        ],
      },
    ],
  },
  {
    "Services and Content": [
      {
        "": [
          "You are granted a personal license and the right to access and utilize the applicable Services, collectively referred to as the “Subscription,” in accordance with the terms and conditions outlined in this Agreement. This Subscription is non-exclusive, non-transferable, and non-sublicensable. It remains valid only as long as this Agreement remains in effect. In this context, a “Paid Subscription” refers to a Subscription that includes access to fee-based sections of the Services.",
          "Our Services may allow you and other users to create, store, and share content, including photos, text, and other data materials (collectively, “User Content”). Subject to this Agreement and the Privacy Policy, you retain all rights in and to your User Content, as between you and MMP labs. Further, MMP labs does not claim ownership of any User Content that you post on or through the Services. As a partial consideration for our Services, you grant MMP labs a nonexclusive, royalty-free, worldwide license to use, reproduce, modify, adapt, create derivative works from, distribute, perform, and display your User Content during the term of this Agreement and thereafter for an indefinite period of time and which license may be terminated not earlier than 10 years after the expiry of this Agreement, solely to provide you with the Services and for the development of our product and services.",
          "Under the User Content License, you retain full ownership of all rights, title, and interest, including Intellectual Property Rights, in your User Content. You are solely responsible for your User Content and agree to indemnify and release MMP Labs, its affiliates, and licensors from any liability related to your User Content and its use by others. You declare and guarantee that: (a) your User Content does not and will not infringe upon any third party’s Intellectual Property Rights, privacy rights, publicity rights, or any applicable laws; and (b) you have obtained and will maintain all necessary licenses, permissions, consents, approvals, and authorizations for your User Content, both during and after the termination of this Agreement. The content, nature, frequency, and quantity of your User Content have not been specifically requested or authorized by MMP Labs (and may or may not be reviewed) and you submit it to the Services at your own risk. Furthermore, you agree not to submit any User Content that contains unlawful information. MMP Labs reserves the right (though not obligated) to determine whether your User Content complies with the requirements outlined in this Agreement and may remove such User Content and/or terminate your access to the Services if it violates this Agreement, at MMP Labs’ discretion and without prior notice.",
          "MMP Labs is not intended to serve as a backup service, and you acknowledge that you should not rely on the Services for the backup or storage of your User Content. MMP Labs will not be held liable for any alterations, interruptions, or termination of the Services, nor for any loss of User Content that may occur.",
          "You hereby waive irrevocably (and agree not to assert) any Moral Rights you may have in and to your User Content against MMP Labs, its Affiliates, MMP Labs licensors, or any other user of the Services. The term “Moral Rights” refers to rights such as paternity or integrity, the right to claim authorship of a work, the right to object to any scoring or derogatory action concerning a work, regardless of whether it may harm the creator’s honor or reputation. This includes similar rights granted by judicial or statutory law in any jurisdiction or under any treaty.",
        ],
      },
    ],
  },
  {
    Privacy: [
      {
        "": [
          "At MMP labs, we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, store, and disclose personal information when you access and use our services. We understand the importance of maintaining the confidentiality and security of your data and strive to ensure compliance with applicable privacy laws. By using our services, you acknowledge and consent to the practices described in this Privacy Policy. We encourage you to read this policy carefully and contact us if you have any questions or concerns about our data practices. Your privacy matters to us, and we are dedicated to safeguarding your personal information.",
        ],
      },
      {
        "Data Collection": [
          "MMP labs collects personal information from users in order to provide and improve our services. This may include information such as your name, email address, contact details, and usage data. We may collect this information directly from you or through automated means such as cookies and other tracking technologies. By using our services, you consent to the collection of your personal information.",
        ],
      },
      {
        "Data Usage": [
          "MMP labs uses the collected data for various purposes, including but not limited to providing and maintaining our services, personalizing user experiences, communicating with users, and improving our offerings. We may also use the data for analytical and research purposes to enhance the performance and functionality of our services.",
        ],
      },
      {
        "Data Storage": [
          "MMP labs takes reasonable measures to protect and secure the data we collect. We store your personal information on secure servers and follow industry best practices to ensure its confidentiality. However, please note that no method of data transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.",
        ],
      },
      {
        Marketing: [
          "We may use your personal information to send you promotional materials, updates, and relevant information about our services, unless you opt out of such communications. You have the right to unsubscribe from these marketing communications at any time.",
        ],
      },
      {
        Cookies: [
          "MMP labs uses cookies and similar technologies to enhance user experiences, analyze trends, and track user interactions on our website. These cookies may be set by us or third-party service providers. By using our services, you consent to the use of cookies in accordance with our Cookie Policy.",
        ],
      },
    ],
  },

  {
    "Notification Provision": [
      {
        "": [
          "The company retains the right to modify these terms periodically at its discretion. In the event of any alterations to our privacy policy, we will notify users of such changes on our homepage and upon logging into their accounts. If there are any adjustments in the way we handle Personally Identifiable Information of our site customers, we will communicate these modifications via email or postal mail to those affected. Our website will display any updates to our privacy policy. Users will be required to accept these changes before being granted further access to our services.",
        ],
      },
    ],
  },
  {
    "Availability of Paid Services and Fees": [
      {
        "": [
          "Certain services and features are exclusively available through a Paid Subscription, and specific Service Content requires payment (such as Credits). By subscribing to a Paid Subscription or paid Service Content, you agree to fulfill the fees and other charges presented to you (referred to as “Fees”). If the payment plan follows a recurring-subscription model, you agree to make the required payments in accordance with the applicable billing cycle. Our Refund Policy governs permissible refunds. Unless otherwise stated in those policies, all Fees are non-refundable and cannot be canceled. Additionally, the amounts payable do not include any applicable sales, use, consumption, VAT, and similar taxes, except for taxes based on MMP lab’s net income.",
        ],
      },
    ],
  },
  {
    "Terms of Subscription": [
      {
        "The following terms govern your subscription:": [
          "Subscription Activation: Upon subscribing to our services, you will gain access to the designated features and content based on the selected subscription plan.",
          "Payment and Billing: You agree to pay the fees associated with your subscription as outlined during the sign-up process. The payment terms may vary depending on the chosen subscription plan, such as recurring payments or one-time fees.",
          "Subscription Duration: Your subscription remains active for the duration specified during the sign-up process. It will automatically renew according to the chosen billing cycle unless you cancel it prior to the renewal date.",
          "Modifications and Cancellations: You have the option to modify or cancel your subscription at any time. Please refer to our provided guidelines or contact our support team for assistance.",
          "Refunds: Refund eligibility is subject to our Refund Policy. Please review the policy to understand the conditions under which refunds may be granted.",
          "Termination: We reserve the right to terminate or suspend your subscription in the event of a violation of our terms of service or for any other valid reason at our discretion.",
          "Changes to Subscription Terms: We may update or modify the subscription terms, including pricing and features, from time to time. Any changes will be communicated to you through appropriate channels, and your continued use of the subscription services constitutes acceptance of the revised terms.",
          "Please carefully review these Subscription Terms and contact us if you have any questions or concerns.",
        ],
      },
    ],
  },
  {
    "Refund Policy": [
      {
        "": [
          "At MMP labs, we value customer satisfaction and strive to deliver high-quality services. In the event that you encounter any issues or are not completely satisfied with your purchase, we have a refund policy in place to address your concerns.",
          "If you believe you are eligible for a refund, please contact our customer support team within 14 days from the date of purchase. Our team will carefully review your request and assess it based on the refund criteria outlined in our policy.",
          "Please note that certain conditions may apply. For instance, refunds may not be granted for services that have been extensively utilized or integrated into your system. Additionally, any violations of our terms of service or misuse of the API may result in ineligibility for a refund.",
          "We aim to process approved refunds in a timely manner, typically through the original payment method used during the purchase. However, please be aware that some fees may be non-refundable, and the decision to grant a refund is ultimately at our discretion.",
          "Thank you for choosing MMP labs service, and we appreciate the opportunity to serve you.",
        ],
      },
    ],
  },
  {
    "Payment Method": [
      {
        "": [
          "You can choose to make payments through various third-party payment methods offered by MMP Labs, including Stripe and supported payment cards. It is important to provide accurate billing information and keep it up to date, including card details and expiration dates. When using a credit or debit card, you confirm that you are the authorized user of the card and authorize MMP Labs (along with any third-party payment processor) to process payments from you, including recurring payments if applicable. If you opt for a third-party payment processor, please note that their terms and conditions will also apply, which are beyond our control, so make sure to review them carefully.",
        ],
      },
    ],
  },
  {
    "Restrictions on Conduct and Content": [
      {
        "": [
          "Users are prohibited from engaging in any conduct or submitting content that violates our terms and guidelines. This includes, but is not limited to, activities such as unauthorized access to the services, spreading harmful or illegal content, infringing upon intellectual property rights, engaging in fraudulent behavior, or violating any applicable laws or regulations. We maintain a strict policy against such conduct and content to ensure a safe and respectful environment for all users.",
        ],
      },
    ],
  },
  {
    "Intellectual Property Rights": [
      {
        "": [
          "We respect the intellectual property rights of others and expect our users to do the same. You must not infringe upon any copyrights or trademarks while using Our Services and the text, graphics, images, photographs, videos, illustrations, trademarks, trade names, page headers, button icons, scripts, service marks, logos, slogans, filters, user generated filters and other content contained therein (collectively, the “MMP labs Content”) are owned by or licensed to MMP labs. This means that you should not reproduce, distribute, modify, or use any copyrighted or trademarked material without the proper authorization or legal rights. We take intellectual property violations seriously and will take appropriate action to address any infringements.",
        ],
      },
    ],
  },
  {
    Feedback: [
      {
        "": [
          "Any feedback, questions, comments, suggestions, ideas, or original and creative materials that you submit to MMP labs regarding our products or services (collectively referred to as “Feedback”) will be considered non-confidential. We are under no obligation to treat such Feedback as confidential, and you grant MMP labs a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and sublicensable right and license to use, reproduce, perform, display, distribute, adapt, modify, re-format, create derivative works of, and exploit the Feedback in any manner, both commercially and non-commercially, in connection with the operation and maintenance of the Services and MMP labs’s business.",
        ],
      },
    ],
  },
  {
    Indemnification: [
      {
        "": [
          "To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless MMP labs and its officers, directors, agents, partners, and employees (collectively referred to as the “MMP labs Parties”) from any loss, liability, claim, demand, damages, expenses, or costs (“Claims”) arising from or related to: (a) your use of our Services; (b) your User Content or Feedback; (c) your violation of this Agreement; (d) your infringement of any rights of another party, including intellectual property or privacy rights; or (e) your conduct in connection with our Services. You will promptly notify the MMP labs Parties of any third-party Claims, cooperate in their defense, and bear all fees, costs, and expenses associated with such Claims, including attorneys’ fees. The MMP labs Parties will have control over the defense or settlement of any third-party Claims. This indemnification provision is in addition to any other indemnities provided in a separate written agreement between you and MMP labs or any other MMP labs Parties.",
        ],
      },
    ],
  },
  {
    Disclaimers: [
      {
        "": [
          "We have no control over, endorse, or assume responsibility for any User Content or third-party content accessible through or linked to by our Services",
          "Your use of our Services is solely at your own risk. Our Services are provided on an “as is” and “as available” basis, without any express or implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. Additionally, MMP labs does not guarantee or warrant that our Services are accurate, complete, reliable, current, or free from errors. While MMP labs strives to ensure the safety of your access and use of our Services, we cannot guarantee that our Services or servers are free from viruses or other harmful components. You assume all risks regarding the quality and performance of the Services.",
        ],
      },
    ],
  },
  {
    "Liability & Accuracy Disclaimer": [
      {
        "": [
          "This website and its components are provided solely for informational, assessment, and analytical purposes. The website shall not be held responsible or liable for the accuracy, usefulness, or availability of its products or services, nor for any errors or omissions that may occur.",
          "MMP labs and the other MMP labs parties will not be held liable to you, under any legal theory of liability, including contract, tort, negligence, strict liability, warranty, or any other, for any indirect, consequential, exemplary, incidental, punitive, or special damages, or for any lost profits, even if MMP labs or the other MMP labs parties have been informed about the possibility of such damages.",
          "The maximum liability of MMP labs and the other MMP labs parties, for any claim arising from or related to this agreement or our services, irrespective of the nature of the action, is restricted to the amount, if any, paid by you to access or use our services.",
          "The limitations stated in this section will not restrict or exclude liability for gross negligence, fraud, or intentional misconduct of MMP labs or the other MMP labs Parties, or for any other circumstances where liability cannot be excluded or limited under applicable law.",
        ],
      },
    ],
  },
  {
    Release: [
      {
        "": [
          "By using MMP labs services, you agree to release the MMP labs, its affiliates, and its partners from any responsibility, liability, claims, demands, and damages (both known and unknown), including those arising from disputes or interactions with other users or third parties. You acknowledge that this release extends to all types of claims, whether based on negligence or otherwise, and that you waive any rights under common law that would limit the scope of this release to only known or suspected claims at the time of agreeing to these terms.",
        ],
      },
    ],
  },
  {
    "Governing Law and Venue": [
      {
        "": [
          "The laws of the applicable jurisdiction govern these terms and conditions and any disputes that may arise from or relate to them. Any legal action or proceeding concerning these terms shall be brought exclusively in the courts of the jurisdiction where the API provider is located or, if applicable, in a mutually agreed-upon jurisdiction.",
        ],
      },
    ],
  },
  {
    Termination: [
      {
        "": [
          "Either party may terminate this agreement at any time by providing written notice to the other party. Upon termination, you will no longer have access to our services, and any rights and licenses granted to you under this agreement will be terminated. The provisions regarding intellectual property rights, confidentiality, limitation of liability, and governing law will survive the termination of this agreement.",
          "In such cases, termination will be effective immediately, unless you have an active Paid Subscription. If you have a current Paid Subscription, termination will be effective at the end of the current Billing Cycle, and you will not be charged for the following Billing Cycle.",
        ],
      },
    ],
  },
  {
    Severability: [
      {
        "": [
          "If any provision of this agreement is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.",
        ],
      },
    ],
  },
];

export default termsAndConditions;
