// src/services/apiService.js
import axios from "axios";

const apiService = axios.create();

const cache = {};

export const clearCache = () => {
  Object.keys(cache).forEach((key) => delete cache[key]);
};

export const fetchDataWithCache = async (baseUrl, endpoint, cacheKey, headers = {}) => {
  if (cacheKey && cache[cacheKey]) {
    console.log("cacheData found", cache[cacheKey]);
    return cache[cacheKey];
  }

  try {
    console.log("request sent begins");
    const response = await apiService.get(`${baseUrl}${endpoint}`, { headers });
    const data = response.data;
    console.log("request sent end");
    if (cacheKey) {
      cache[cacheKey] = data;
    }

    return data;
  } catch (error) {
    console.error(`Error fetching data for endpoint ${endpoint}:`, error);
    throw error;
  }
};

export const postDataWithCache = async (baseUrl, endpoint, cacheKey, postData, headers = {}) => {
  try {
    const response = await apiService.post(`${baseUrl}${endpoint}`, postData, { headers });
    const data = response.data;

    if (cacheKey) {
      cache[cacheKey] = data;
    }

    return data;
  } catch (error) {
    console.error(`Error posting data to endpoint ${endpoint}:`, error);
    throw error;
  }
};
