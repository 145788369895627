// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";
import PageLayout from "layouts/PageLayout";

// Authentication layout components
import bgImage from "assets/images/cover.jpg";
import Footer from "layouts/Footer";

function CoverLayout({ coverHeight, children, shrinkCardWidth }) {
  const image = bgImage;
  return (
    <>
      <PageLayout>
        <DefaultNavbar
          action={{
            type: "external",
            route: "https://creative-tim.com/product/material-dashboard-react",
            label: "free download",
          }}
          transparent
          light
        />
        <MDBox
          width="calc(100% - 2rem)"
          minHeight={coverHeight}
          borderRadius="xl"
          mx={2}
          my={2}
          pt={6}
          pb={28}
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.7),
                rgba(gradients.dark.state, 0.4)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <MDBox mt={{ xs: -20, lg: -18 }} px={1} width="calc(100% - 2rem)" mx="auto">
          <Grid container spacing={1} justifyContent="center">
            {shrinkCardWidth ? (
              <Grid item xs={10} sm={16} md={5} lg={4} xl={4}>
                {children}
              </Grid>
            ) : (
              <Grid item xs={11} sm={9}>
                <MDBox
                  variant="gradient"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={8}
                  p={3}
                  mb={5}
                >
                  {children}
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </PageLayout>
      <MDBox
        width="calc(100% - 2rem)"
        minHeight="20vh"
        borderRadius="xl"
        alignItems="center"
        mx={2}
        my={2}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.7),
              rgba(gradients.dark.state, 0.4)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <Footer light />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

// Setting default props for the CoverLayout
CoverLayout.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  coverHeight: PropTypes.string,
  children: PropTypes.node,
  shrinkCardWidth: PropTypes.bool.isRequired,
};

export default CoverLayout;
