import React from "react";

import image from "assets/images/lib_globe.jpeg";
import freeImage from "assets/images/Free.jpg";
import developerFriendlyImage from "assets/images/Developer-friendly.jpg";
import reliableFlexibleAndScalableImage from "assets/images/Cloud-Scalability-and-Flexibility.jpg";
import comprehensiveSolutionImage from "assets/images/Comprehensive-Solutions.jpg";
import responsiveSupportImage from "assets/images/Support.png";
import continuousDevelopmentImage from "assets/images/Development.jpg";
import richCountryDataImage from "assets/images/Geography.jpg";
import Section from "../Sections/Section";
import MDBox from "components/MDBox";
import ImageCard from "Cards/ImageCard";

function WhyUs() {
  const descriptionForFreeCard =
    "Get the most value for your investment and enjoy cost-effective API solutions without compromising on quality. Our comprehensive range of APIs empowers developers to build innovative applications and solutions without the burden of cost, unlocking new possibilities for all";
  const descriptionForDeveloperFriendlyCard =
    "Our APIs are designed with simplicity and ease of use in mind. We provide clear documentation, intuitive interfaces, extensive code samples, and examples to facilitate smooth integration and save you valuable development time. With our developer-friendly APIs, we aim to empower developers of all skill levels to seamlessly integrate powerful functionalities into their applications and unlock new possibilities";
  const descriptionForReliableFlexibleAndScalableCard =
    "Our APIs are built on a robust infrastructure, ensuring high availability and fast response times. Trust us to deliver the performance your applications need. We understand that your needs may change over time. Our APIs offer flexibility and scalability, allowing you to adapt and grow your applications seamlessly as your business evolves.";
  const descriptionForComprehensiveSolutionsCard =
    "We offer a wide range of APIs that cater to diverse industries and use cases. Whether you need data retrieval, real-time updates, or advanced functionalities, our comprehensive API suite has got you covered. Our comprehensive API solutions cover a wide range of industries and use cases, providing developers with a one-stop-shop for their integration needs. From data retrieval and manipulation to advanced analytics and real-time updates, our APIs offer a complete toolkit to build robust and innovative applications.";
  const descriptionForResponsiveSupportCard =
    "Our dedicated support team is available to assist you promptly and efficiently. Whether you have technical questions or need assistance with API implementation, our experts are here to help you every step of the way. We strive to provide timely and effective solutions, ensuring a smooth and seamless experience as you harness the power of our APIs. You can rely on our knowledgeable support team to guide you through any hurdles and help you maximize the value of our API services.";
  const descriptionForRichCountryDataCard =
    "Our APIs provide a comprehensive and up-to-date database of information for every country. From basic details like country names and codes to more in-depth data such as population, languages spoken, time zones, and currencies – we offer a wealth of information at your fingertips.";
  const descriptionForContinuousImprovementCard =
    "We are dedicated to enhancing our services based on user feedback and evolving industry needs. Expect regular updates, new features, and improvements to ensure that our APIs stay ahead of the curve.";

  return (
    <Section
      title="Why Us?"
      color="dark"
      bgColor="white"
      description="Choosing the right API partner is crucial for the success of your project, and here's why you should consider us:"
    >
      <MDBox pt={{ xs: 2, md: 2 }} display="flex" alignItems="center" lineHeight={2}>
        <ImageCard
          leftImage={false}
          image={richCountryDataImage}
          title="Rich Country Data:"
          description={descriptionForRichCountryDataCard}
        />
      </MDBox>
      <MDBox pt={{ xs: 2, md: 2 }} display="flex" alignItems="center" lineHeight={2}>
        <ImageCard
          leftImage={true}
          image={freeImage}
          title="Freemium:"
          description={descriptionForFreeCard}
        />
      </MDBox>
      <MDBox pt={{ xs: 2, md: 2 }} display="flex" alignItems="center" lineHeight={2}>
        <ImageCard
          leftImage={false}
          image={developerFriendlyImage}
          title="Developer-Friendly:"
          description={descriptionForDeveloperFriendlyCard}
        />
      </MDBox>
      <MDBox pt={{ xs: 2, md: 2 }} display="flex" alignItems="center" lineHeight={2}>
        <ImageCard
          leftImage={true}
          image={continuousDevelopmentImage}
          title="Continuous Improvement:"
          description={descriptionForContinuousImprovementCard}
        />
      </MDBox>
      <MDBox pt={{ xs: 2, md: 2 }} display="flex" alignItems="center" lineHeight={2}>
        <ImageCard
          leftImage={false}
          image={reliableFlexibleAndScalableImage}
          title="Reliable, Flexible and Scalable:"
          description={descriptionForReliableFlexibleAndScalableCard}
        />
      </MDBox>
      <MDBox pt={{ xs: 2, md: 2 }} display="flex" alignItems="center" lineHeight={2}>
        <ImageCard
          leftImage={true}
          image={comprehensiveSolutionImage}
          title="Comprehensive Solutions:"
          description={descriptionForComprehensiveSolutionsCard}
        />
      </MDBox>
      <MDBox pt={{ xs: 2, md: 2 }} display="flex" alignItems="center" lineHeight={2}>
        <ImageCard
          leftImage={false}
          image={responsiveSupportImage}
          title="Responsive Support:"
          description={descriptionForResponsiveSupportCard}
        />
      </MDBox>
    </Section>
  );
}

export default WhyUs;
