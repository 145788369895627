import CoverLayout from "layouts/CoverLayout";
import TermsAndConditions from "ui-components/TermsAndConditions";

function TermOfService() {
  return (
    <CoverLayout shrinkCardWidth={false}>
      <TermsAndConditions />
    </CoverLayout>
  );
}

export default TermOfService;
