import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button, Snackbar } from "@mui/material";
import CopyIcon from "@mui/icons-material/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import codeSnippets from "./code";
import MDButton from "components/MDButton";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";

const CodeSnippetContent = ({ tabName, tabValue }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <MDBox
      p={3}
      border="1px solid #ddd"
      borderRadius="8px"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      color="white"
      style={{ backgroundColor: "white" }}
    >
      <MDTypography variant="h5" mb={1} color="info">
        Code Snippet - {tabName}
      </MDTypography>
      <MDBox position="relative">
        <CopyToClipboard text={codeSnippets[tabValue]} onCopy={handleCopy}>
          <MDButton
            variant="gradient"
            color="info"
            startIcon={<CopyIcon />}
            style={{ position: "absolute", top: -50, right: 0 }}
          >
            Copy
          </MDButton>
        </CopyToClipboard>
        <MDBox
          p={3}
          border="1px solid #ddd"
          borderRadius="8px"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
          color="white"
          style={{ backgroundColor: "white" }}
        >
          <SyntaxHighlighter language={tabName} style={dracula}>
            {codeSnippets[tabValue]}
          </SyntaxHighlighter>
        </MDBox>
      </MDBox>
      <Snackbar
        open={copied}
        autoHideDuration={1500}
        onClose={() => setCopied(false)}
        message="Copied to clipboard!"
      />
    </MDBox>
  );
};

CodeSnippetContent.propTypes = {
  tabName: PropTypes.string.isRequired,
  tabValue: PropTypes.number.isRequired,
};

export default CodeSnippetContent;
