// src/services/dataService.js
import awsExports from "../../aws-exports";
import { fetchDataWithCache, postDataWithCache, clearCache } from "restClient";
import { Auth } from "aws-amplify";
import DashboardModel from "model/DashboardModel";

const BASE_URL = ""; //awsExports.aws_cloud_logic_custom[0].endpoint;
const API_ENDPOINTS = {
  DASHBOARD_INFO: "/apis/devPortal/v1/portal/dashboard",
  CONTACT_US: "/apis/devPortal/v1/portal/dashboard",
};

export const getHeaders = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const token = currentUser.signInUserSession?.idToken.jwtToken;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log("token " + token);
    return headers;
  } catch (error) {
    console.error("Error getting headers:", error);
    throw error;
  }
};

export const fetchDashboardInfo = async () => {
  try {
    const response = await fetchDataWithCache(
      BASE_URL,
      API_ENDPOINTS.DASHBOARD_INFO,
      "dashboardInfo",
      await getHeaders()
    );

    // Map the response to DashboardModel
    const dashboardModel = new DashboardModel(response);
    return dashboardModel;
  } catch (error) {
    console.error("Error fetching dashboard info:", error);
    throw error;
  }
};

export const postContactUsInfo = async (postData) => {
  try {
    return await postDataWithCache(
      BASE_URL,
      API_ENDPOINTS.CONTACT_US,
      "contact-us",
      postData,
      await getHeaders()
    );
  } catch (error) {
    console.error("Error posting contactus data:", error);
    throw error;
  }
};

export const clearDataCache = () => {
  clearCache();
};
