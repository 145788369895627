/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  sales: {
    labels: getDaysUntilCurrentDate(),
    datasets: {
      label: "Daily Usage",
      data: [
        10, 12, 20, 25, 18, 30, 22, 28, 15, 12, 25, 20, 18, 23, 28, 32, 20, 15, 10, 25, 30, 28, 22,
        18, 15, 12, 30, 25, 20, 18, 22,
      ].slice(0, getDaysUntilCurrentDate().length),
    },
  },
  tasks: {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Desktop apps", data: [50, 40, 300, 220, 500, 250, 400, 230, 500] },
  },
};
function getDaysUntilCurrentDate() {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;

  return Array.from({ length: currentDay }, (_, i) => {
    const day = i + 1;
    return `${currentMonth}/${day}`;
  });

  function getDaysInMonth() {
    // month is 0-indexed in JavaScript (January is 0, February is 1, ..., December is 11)
    const getDaysInMonth = (year, month) => {
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      return Array.from({ length: daysInMonth }, (_, i) => {
        const day = i + 1;
        const date = new Date(year, month, day);
        return date.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit" });
      });
    };

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    return getDaysInMonth(currentYear, currentMonth);
  }
}
