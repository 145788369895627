import MDBox from "components/MDBox";
import DashboardCoverLayout from "layouts/DashboardCoverLayout";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import RequireAuth from "layouts/authentication/RequireAuth";
import WhyUs from "ui-components/WhyUs";

function DashboardWhyUs() {
  return (
    <RequireAuth>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <DashboardCoverLayout profile={false}>
          <WhyUs />
        </DashboardCoverLayout>
      </DashboardLayout>
    </RequireAuth>
  );
}

export default DashboardWhyUs;
